import React, { Component } from 'react'

export default class HeroImage extends Component {
  render() {
    return (
      <section>
        <div className="has-text-centered">
          <img src={this.props.image} className="heroImage"/>
        </div>
      </section>
    )
  }
}
