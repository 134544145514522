import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/layout"
import PageTitle from '../../components/PageTitle'
import HeroImage from '../../components/HeroImage'

import { Link } from 'gatsby'

import PageHeaderQuote from '../../components/PageHeaderQuote'

import imageTheDetails from '../../images/content/projects/2018-19-feeding-program/the-details.jpg'
import imageFunRun1 from '../../images/content/projects/2018-19-feeding-program/2019-FunRun.png'

import heroImage from '../../images/content/projects/2019-20-feeding-program/2019-feeding-program-hero.jpg'
import portraits from '../../images/content/projects/2019-20-feeding-program/portraits.jpg'
import alao01 from '../../images/content/projects/2019-20-feeding-program/fp-alao-dc-108.jpg'
import alao02 from '../../images/content/projects/2019-20-feeding-program/fp-alao-dc-120.jpg'
import alao03 from '../../images/content/projects/2019-20-feeding-program/fp-alao-dc-178.jpg'
import alao04 from '../../images/content/projects/2019-20-feeding-program/2019-fp-alao-1.jpg'
import alao05 from '../../images/content/projects/2019-20-feeding-program/2019-fp-alao-2.jpg'
import alao06 from '../../images/content/projects/2019-20-feeding-program/2019-fp-alao-3.jpg'
import alao07 from '../../images/content/projects/2019-20-feeding-program/2019-fp-alao-4.jpg'
import alao08 from '../../images/content/projects/2019-20-feeding-program/2019-fp-alao-5.jpg'
import alao09 from '../../images/content/projects/2019-20-feeding-program/fp-alao-dc-69.jpg'

const heroStyle = {
  objectFit: 'cover',
  width: '100vw',
  height: '80vh'
}
export default class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }


  render() {
    return (
      <Layout>
        <PageTitle title="Feeding Program" subtitle="2019-2020" />
        {/* <HeroImage image={heroImage} /> */}
        <HeroImage image={heroImage} />
        <PageHeaderQuote quote="YOU CANNOT IMPROVE THE QUALITY OF A CHILD’S EDUCATION IF YOU DO NOT FIRST IMPROVE THE CHILD’S LIKELIHOOD OF ACTUALLY RECEIVING AN EDUCATION"
                          author="Unknown" />
        
        {/* The Program */}
        <div className="section has-background-white-ter">
          <div className="container">
            <div className="columns is-multiline   ">
              <div className="column is-half">
                <img src={alao02} alt="The Program" />
              </div>
              <div className="column is-half">
                <h3 className="title is-3 has-text-grey-darker is-uppercase">The Program</h3>
                <div className="separator"></div>
                <br/>
                <p>
                  The <Link to="/projects/2018-19-feeding-program" id="2019FP-To-2018FP">2018/2019 Pilot Feeding Program</Link> was a success!
                  The faces you see below are a few of the 78 kids that the Sum of One community supported and enabled learning.
                </p>
                <br/>
                <p>
                  The feeding program like the one we are funding is fairly basic, providing a nutritional daily breakfast. 
                  Students were assessed by signs of obvious malnutrition, sporadic attendance, or based on firsthand knowledge of their home situation. 
                  In the case of Alao Elementary, the entire student population falls into one or all of these categories.
                </p>
                <br/>
                <p>The cost to provide one meal for one child during the course of the school year:</p>
                <p>$2.50/wk, $10.00/mo, or $85.00/yr</p>
                <br/>
                <p>In the new school year (2019/2020), we are continuing to support Alao Elementary and looking to add a new school. An addition of 89 new students!</p>
                <br/>
                <p>You can enable learning by investing financially. </p>
                <p><Link to="/invest" className="button is-primary" id="2019FP-To-Invest">Click here to get started!</Link></p>
              </div>
            </div>
          </div>
        </div>

        {/* The Gallery */}
        <div className="section has-background-white-ter-">
          <div className="container">
            <h3 className="title is-3 has-text-grey-darker is-uppercase">The Gallery</h3>
            <div className="separator"></div>
            <p>
              Some snapshots from our trip in January of 2019 as well as images captured of the feeding program in action!
            </p>
            <br/>
            <div className="columns is-desktop">
              <div className="column">
                <figure className="image is-4by3">
                  <img src={alao01} />
                </figure>
              </div>
              <div className="column">
                <figure className="image is-4by3">
                  <img src={alao09} />
                </figure>
              </div>
              <div className="column">
                <figure className="image is-4by3">
                  <img src={alao03} />
                </figure>
              </div>
            </div>

            <div className="columns is-desktop">
              <div className="column">
                <figure className="image is-4by3">
                  <img src={alao05} />
                </figure>
              </div>
              <div className="column">
                <figure className="image is-4by3">
                  <img src={alao06} />
                </figure>
              </div>
              <div className="column">
                <figure className="image is-4by3">
                  <img src={alao07} />
                </figure>
              </div>
            </div>
          </div>
        </div>

        {/* Fun Run Promo */}
        <div className="section  has-background-white-ter">
          <div className="container">
            <div className="columns is-multiline ">
              <div className="column is-half">
                <img src={imageFunRun1} alt="2018 Fun Run" />
              </div>
              
              <div className="column is-half">
                <h3 className="title is-3 has-text-grey-darker is-uppercase">Fun Run</h3>
                <div className="separator"></div>
                <br/>
                <p>
                  To help fund and raise awareness for the feeding program, we are hosting an amazing event on <strong>November 2, 2019</strong> and we would love for you to be a part of it. 
                  <br/><br/>
                  Bring out the whole family and support us at our <strong><a href="https://funrun.sumofone.org" id="2019FP-to-FunRunWeb">second annual 5K Fun Run!</a></strong> 
                  <br /><br />
                  <a href="https://sumofone-funrun.eventbrite.com" className="button is-primary is-large" id="2019FP-to-FunRunRegister">Register Today!</a>
                  {/* For more information on the 
                  Fun Run and how you can be a part, please visit us here. */}
                </p>
              </div>

            </div>
          </div>
        </div>
        
      </Layout>
    );
  }
}